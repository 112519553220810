.ButtonContainer{
    display: flex;
    justify-content: space-around;
    height: 33%;
    gap: 25%;
}

.PollContainer{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 50%;
    width: 50%;
}


.PollQuestionContainer{
    border-radius: 25px;
    color: white;
    background-color: #FF7700;
}

.PollQuestionText{

}

button{
    flex-grow: 1;
}

.VoteButtonYes {
    background-color: green;
}

.VoteButtonNo{
    background-color: red;
}