button {
    height: 75%;

    color: white;
    background: #FF9C46;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
}
.GoogleLoginButton{
    display: flex;
    align-items: center;
}

.GoogleLogoImg{
}