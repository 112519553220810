.TopBar{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
    background-color: #FF7700;
    box-shadow: 0px 2px 5px #222222ff;
}

.TopBarContainerLeft{
    flex-grow: 1;
    display: flex;
    overflow: hidden;
}

.TopBarContainerRight{
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: row-reverse;
    gap: 10px;
}

.App-logo {
}

.buttons{
    flex-grow: 1;
    height: 100%;
}