.App {
    text-align: center;
    position: absolute;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
}

.App-body{
    display: flex;
    flex-grow: 1;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

.App-header {
    height: 48px;
    background-color: #222222;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}