.PollForm {
    display: flex;
    flex-direction: column;
    gap: 5px;
    color:white;
}

.EnterQuestionContainer{
    background: #FF7700;
    padding: 10px;
}

.CreateAPollTitleText{
    -webkit-text-stroke: 1px black; /* width and color */
    color:white;
}